import { showEphemeral, show_layout_modal } from './global';
import { EditTable } from "./lib/edit_table.js";
import { SelectizeMultipleSelect } from "./lib/selectize_multiple_select";

class AdminManagedFeaturesPage {
  init() {
    this.setup_merge_all();
    this.setup_copy_right();
  }

  setup_merge_all() {
    $('a#merge-all').on('click', (e) => {
      e.preventDefault();
      this.merge_all_values();
    });
  }

  setup_copy_right() {
    $('tr.values-row a.copy-right').on('click', (e) => {
      e.preventDefault();
      const llm_td = $(e.currentTarget).parents('td.llm-value');
      const llm_value = $(llm_td).text().trim();
      const tr_parent = $(llm_td).parents('tr.values-row');
      const current_input = $(tr_parent).find('textarea.current-value');
      $(current_input).val(llm_value);
    });
  }

  merge_all_values() {
    $('tr.values-row').each( (index, row) => {
      $(row).find('td.llm-value').each( (index, llm) => {
        const current_value = $(row).find('.current-value');
        current_value.val($(llm).text().trim());
      });
    });
  }
}

$(document).on('turbo:load', function() {
  if ($("body.admin_managed_features.merge").length == 0) {
    return;
  }
  console.log("page load in admin_managed_features.js");

  var page = new AdminManagedFeaturesPage();
  page.init();
} );
