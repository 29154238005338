import { EditTable } from "./lib/edit_table.js";


$(document).on('turbo:load', function() {
  if ($("body.admin_bandwidth_prices.index").length == 0) {
    return;
  }
  console.log("page load in admin_bandwidth_prices.js");

  var bandwidth_prices_page = new EditTable(true, '/admin/bandwidth_prices');
  bandwidth_prices_page.init();
} );
