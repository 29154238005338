class AdminEmailEditionsPage {

  setupRowsSubmit() {
    var meta = $('meta[name=csrf-token]');
    var csrfToken = meta.attr('content');
    $('.button-row-class').each( (index, elt) => {
      $(elt).on('click', (event) => {
        var editionId = $(elt).attr('id').match(/(\d+)$/)[0]
        console.log(`${editionId} click`);

        var params = { new_state: $(elt).data('next-state') };
        $.ajax({ method: "PUT",
          url: `/admin/email_editions/${editionId}`,
          data: JSON.stringify(params),
          headers: { "Accept": "text/html", "X-CSRF-Token": csrfToken },
          dataType: 'html',
          contentType: "application/json; charset=utf-8",
          success: (data, textStatus, xhr) => {
            console.log("put success");
            $(`#edition-row-${editionId}`).html(data);
          },
          error: (data, textStatus, xhr) => {
            console.log("put failure");
          }
        });
      });
    });
  }

  setupJobButton(jobType) {
    var meta = $('meta[name=csrf-token]');
    var csrfToken = meta.attr('content');
    var elt = `#button-job-${jobType}`;
    $(elt).on('click', (event) => {
      $.ajax({ method: "PUT",
        url: `/admin/email_editions/create_email_job`,
        headers: { "X-CSRF-Token": csrfToken },
        data: { job_type: jobType },
        success: (data, textStatus, xhr) => {
          console.log("put success");
        },
        error: (data, textStatus, xhr) => {
          console.log("put failure");
        }
      });
    });
  }

}

$(document).on('turbo:load', function() {
  if ($("body.admin_email_editions.index").length == 0 ) {
    return;
  }
  console.log("page load in admin_email_editions.js");

  var admin_email_editions_page = new AdminEmailEditionsPage();

  admin_email_editions_page.setupRowsSubmit();
  admin_email_editions_page.setupJobButton('populator');
  admin_email_editions_page.setupJobButton('submitter');
  admin_email_editions_page.setupJobButton('verifier');
} );
