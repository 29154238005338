import { showEphemeral, show_layout_modal } from './global';
import { EditTable } from "./lib/edit_table.js";
import { SelectizeMultipleSelect } from "./lib/selectize_multiple_select";

class AdminImportPlansPage {
  init() {
    this.setup_edit_table();
    this.setup_import_button();
    this.load_plans();
    this.setup_create_buttons();
    this.setup_update_buttons();
    this.setup_hoster_plan_select($(".custom-plans-select").first());
  }

  load_plans() {
    var product_range_id = $('#plans-table').data('product-range-id');
    console.debug(`loading plans for product range ${product_range_id}`);
    var url = `/admin/product_ranges/${product_range_id}/load_plans`;
    var params = {};

    $.get(url, params, function(data) {
      $("#plans-table").html(data);
    }).fail(function() {
      console.error("load_plans async call failed");
    });
  }

  setup_import_button() {
    var csrfToken = $('meta[name=csrf-token]').attr('content');
    $('#import-button').on('click', (e) => {
      e.preventDefault();

      var product_range_id = $('#plans-table').data('product-range-id');
      var plans = $('#plans-json').val();
      $.ajax({ method: "POST",
          url: `/admin/product_ranges/${product_range_id}/import_plans`,
          headers: { "X-CSRF-Token": csrfToken },
          data: { plans: plans },
          success: (data, textStatus, xhr) => {
            console.log(`post success`);
            showEphemeral("Plans imported successfully");
            $("#plans-table").html(data);
            this.setup_create_buttons();
            this.setup_update_buttons();
          },
          error: (data, textStatus, xhr) => {
            var s = `Error code: ${data.status}, ${data.responseJSON.error}`
            show_layout_modal(s);
            console.log(s);
          }
      });
    });
  }

  setup_create_buttons() {
    this.setup_save_buttons('create', '.create-button');
  }

  setup_update_buttons() {
    this.setup_save_buttons('update', '.update-button');
  }

  setup_save_buttons(operation, button_selector) {
    var meta = $('meta[name=csrf-token]');
    var csrfToken = meta.attr('content');
    $('table#plans-table').on('click', button_selector, (event) => {
      event.preventDefault();
      var editPlanRow = $(event.currentTarget).parents('tr.edit-item-row');
      var productRangeId = $('table#plans-table').data('product-range-id');
      var matchingPlanId = editPlanRow.data('matching-plan-id');

      var params = {};
      editPlanRow.find(`.item-param`).each( (index, elt) => {
        var val;
        if ($(elt).attr('type') === 'checkbox') {
          val = $(elt).is(':checked');
        } else {
          val = $(elt).val();
        }
        params[$(elt).attr('name')] = val;
      });

      var http_method = "PUT";
      var url = `/admin/product_ranges/${productRangeId}/plans/${matchingPlanId}/update`;
      if (operation == 'create') {
        http_method = "POST";
        url = `/admin/product_ranges/${productRangeId}/plans/create`;
      }

      $.ajax({
        method: http_method,
        url: url,
        headers: { "X-CSRF-Token": csrfToken },
        contentType: "application/json",
        data: JSON.stringify(params),
        success: (data, textStatus, xhr) => {
          console.debug(`${operation} success`);
          showEphemeral(`Successfully ${operation}d plan`);
          var newPlanShow = $($.parseHTML(data));
          var editPlanContainer = editPlanRow.parents('td.edit-plan-container').first();
          var showPlanCell = this.sibling_show_container(editPlanContainer);
          showPlanCell.html(newPlanShow);

          var saveButton = editPlanRow.find('a.save-button').first();
          saveButton.removeClass('create-button');
          saveButton.addClass('update-button');
          saveButton.html('Update');
          var showPlanDiv = showPlanCell.find('div.show-plan').first();
          editPlanRow.data('matching-plan-id', showPlanDiv.data('plan-id'));
        },
        error: (data, textStatus, xhr) => {
	        var respBody = data.responseJSON;
          console.error(`err: ${respBody.error}, code: ${data.status}`);
          show_layout_modal(respBody.error);
        }
      });
    });
  }

  sibling_show_container(edit_container) {
    var containingRow = edit_container.parents('tr.around-cells').first();
    return containingRow.find('td.show-plan-container').first();
  }

  setup_hoster_plan_select(select_elt) {
    var selectizeBox = new SelectizeMultipleSelect(`#${$(select_elt).attr('id')}`, 'Type provider or plan names', 'plans', undefined, undefined, false);
    selectizeBox.init_select();
    selectizeBox.setup_select_changes();
  }

  setup_edit_table() {
    var edit_table = new EditTable(false, '/admin/product_ranges', '');
    edit_table.init();
  }
}

$(document).on('turbo:load', function() {
  if ($("body.admin_product_ranges.import_plans").length == 0) {
    return;
  }
  console.log("page load in admin_import_plans.js");

  var page = new AdminImportPlansPage();
  page.init();
} );
