import VChartsLoader from './lib/vcharts_loader.js';

class TrialsWebPage {
  constructor(data_elt_id, cpu_canvas_id, time_canvas_id) {
    this.data_elt_id = data_elt_id;
    this.cpu_canvas_id  = cpu_canvas_id;
    this.time_canvas_id = time_canvas_id;
    this.vchartsLoader = new VChartsLoader();
  }

  init() {
    this.vchartsLoader.init();
    this.extract_data();
    this.display_charts();
  }

  display_charts() {
    this.load_trial_response_time_chart();
    this.load_trial_cpu_util_chart();
  }

  extract_data() {
    var trial_id_element = $(this.data_elt_id);
    if (trial_id_element.length == 0) {
      console.warn(this.data_elt_id + " is missing from trials show page");
      return;
    }
    console.debug("trial_id=" + trial_id_element.data('trial-id'));

    this.trial_id = parseInt(trial_id_element.data('trial-id'));
  }


  load_trial_response_time_chart() {
    if ($(this.time_canvas_id).length == 0) {
      return;
    }

    var canvas = $(this.time_canvas_id);
    var ctx = canvas.get(0).getContext("2d");

    $.ajax({
      type: 'GET',
      url: "/trials/" + this.trial_id + "/response_time_data",
      dataType: 'json',
      success: function (data) {
        this.vchartsLoader.vpsb_line_chart(ctx, data);
      }
    })
  }

  load_trial_cpu_util_chart() {
    if ($(this.cpu_canvas_id).length == 0) {
      return;
    }

    var canvas = $(this.cpu_canvas_id);
    var ctx = canvas.get(0).getContext("2d");

    $.ajax({
      type: 'GET',
      url: "/trials/" + this.trial_id + "/cpu_util_data",
      dataType: 'json',
      success: (data) => {
        this.vchartsLoader.vpsb_line_chart(ctx, data);
      }
    })
  }
}

$(document).on('turbo:load', function() {
  if ($("body.trials.web").length == 0) {
    return;
  }
  console.log("page load in trials.js");

  var trial_web_page = new TrialsWebPage("#trial-charts", "#trial_cpu_util_chart", "#trial_response_time_chart");
  trial_web_page.init();
} );
