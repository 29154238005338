import { showEphemeral, show_layout_modal } from './global';

class AdminLLMCustomPage {
  setupRead() {
    $('#submit-read').on('submit', (event) => {
      var formElt = event.currentTarget;
      event.preventDefault();
      $.ajax({
        method: 'POST',
        url: $(formElt).attr('action'),
        data: $(formElt).serialize(),
        success: (data) => {
          console.debug('POST puppeteer_read success');
          console.debug(data);

          $('div.modal-dialog').addClass('modal-xl');
          $('#layout-modal-body').html(data);
          $('#layout-modal').on('shown.bs.modal', () => {
            $('#layout-modal-close').trigger('focus');
          });
          $('#layout-modal').modal({});
        },
        error: (data) => {
          var respBody = data.responseJSON;
          show_layout_modal(`Something went wrong: state=${respBody.state} - ${data.status} - ${respBody.error}`);
          console.log(`err: ${respBody.error}, code: ${data.status}`);
        }
      });
    });
  }
}

$(document).on('turbo:load', function() {
  if ($("body.admin_llm_custom.puppeteer_new").length == 0) {
    return;
  }
  console.log("page load in admin_llm_custom.js");

  var page = new AdminLLMCustomPage();
  page.setupRead();
} );
