import { EditTable } from "./lib/edit_table.js";

$(document).on('turbo:load', function() {
  if ($("body.admin_hosters.billing").length == 0) {
    return;
  }
  console.log("page load in admin_billing.js");

  var billing_page = new EditTable(false, '/admin/hosters', '/billing');
  billing_page.init();
} );
