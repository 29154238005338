import CookieManager from './cookie_manager';
import { vpsb_lazy_load_images } from '../vpsb/lazy_loader.js';


function vpsbIncrementPagesInSession(cookies) {
  var numPages = cookies.get('numPagesInSession');
  if (typeof numPages !== 'undefined') {
    numPages++;
  } else {
    numPages = 1;
  }

  cookies.set('numPagesInSession', numPages, 1);
}

function postPageview() {
  var meta_csrf = $('meta[name=csrf-token]');
  if (meta_csrf.length == 0) {
    return;
  }

  var meta_env = $('meta[name=environment]');
  if (meta_env.length > 0 && meta_env.attr("content") === "development") {
    return;
  }

  var advertiser = $(".sidebar-square-image").data("advertiser");

  var stats = {
    advertiser: advertiser,
    num_vpsb_ad_unit: $('#vpsb-square-image').length,
    num_codefund_ad_unit: 0,
  };

  var csrfToken = meta_csrf.attr('content');
  $.ajax({ method: "POST",
         url: `/api/pageviews`,
         data: stats,
         dataType: 'json',
         headers: { "X-CSRF-Token": csrfToken }
  });
}

function csrfToken() {
  var meta = $('meta[name=csrf-token]');
  if (meta.length == 0) {
    return "";
  }
  return meta.attr('content');
}

function logAdClick() {
  $('#vpsb-square-image').on('click', (event) => {
    $.ajax({ method: "POST",
           url: `/api/pageviews/ad_click`,
           data: { advertiser: $(event.currentTarget).data('advertiser') },
           headers: { "X-CSRF-Token": csrfToken() }
    });
  });
}

function setupNavbarSignOut() {
  $('.navbar').on('click', 'a[data-method="delete"]', (e) => {
    e.preventDefault();
    $.ajax({
      method: "DELETE",
      url: $(e.currentTarget).attr('href'),
      headers: { "X-CSRF-Token": csrfToken() },
      success: () => {
        Turbo.visit('/');
      },
      error: () => {
        console.error("Sign out failed");
      }
    });
  });
}

function moveSidebarAd() {
  if ($(window).width() >= 990)
    return;

  if ($("#mobile-square-ad-wrapper").length == 0 || $("#vpsb-square-image").length == 0)
    return;

  $("#mobile-square-ad-wrapper").empty();
  $("#vpsb-square-image").appendTo("#mobile-square-ad-wrapper");
}

function plausiblePageview() {
  if ($('meta[name=environment]').attr('content') !== 'development') {
    plausible('pageview');
  }
}


$(document).on('turbo:load', function() {
  var cookies = new CookieManager();
  vpsbIncrementPagesInSession(cookies);
  moveSidebarAd();
  vpsb_lazy_load_images();
  plausiblePageview();
  postPageview();
  setupNavbarSignOut();

  history.scrollRestoration = 'auto';
});
