import { SelectizeMultipleSelect } from "./lib/selectize_multiple_select";

class AdminPlansPage {
  constructor() {
    $('#hoster-form input#hoster').trigger('focus');
  }

  setupHosterFilterChange() {
    $('#hoster-filter').on("input", () => {
      var pattern = $('#hoster-filter').val();

      $('#quick-edit').attr('href', `/accounts/plans/edit?hoster=${pattern}`);
      $('#quick-edit').html(`Quick Edit ${pattern}`);

      $('.plan-row').each( (index, element) => {
        if ( $(element).data('hoster-name').toLowerCase().includes(pattern.toLowerCase())) {
          $(element).removeClass('hidden');
        } else {
          $(element).addClass('hidden');
        }
      });
    });
  }
}

function setup_hoster_plan_select(select_elt) {
  var selectizeBox = new SelectizeMultipleSelect(`#${$(select_elt).attr('id')}`, 'Type provider or plan names', 'plans', undefined, undefined, false);
  selectizeBox.init_select();
}

$(document).on('turbo:load', function() {
  if ($("body.admin_plans.edit,body.admin_plans.new").length > 0 ) {
    setup_hoster_plan_select('#plan_product_range_id');
    return;
  }

  if ($("body.admin_plans.index").length == 0 ) {
    return;
  }
  console.log("page load in admin_plans.js");

  var admin_plans_page = new AdminPlansPage();

  admin_plans_page.setupHosterFilterChange();
} );
