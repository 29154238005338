import { showEphemeral, show_layout_modal } from './global';

class AdminHosterInvoices {
  loadInvoiceList() {
    var hosterId = $('div#invoice-list').data('hoster-id');
    $.get(`/admin/hosters/${hosterId}/invoice_list`, (data) => {
      $('div#invoice-list').html(data);
    }).fail( (data) => {
      var respBody = data.responseJSON;
      console.error(`err: ${respBody.error}, code: ${data.status}`);
    });
  }

  setupRefreshButton() {
    $('a#refresh-list').on('click', (e) => {
      this.loadInvoiceList();
      e.preventDefault();
    });
  }

  setupCreateMonthlyInvoice() {
    $('form#create-invoice-form').on('submit', (e) => {
      var payment_provider = $(e.currentTarget).data("payment-provider");
      var pay_per_referral = $(e.currentTarget).data("pay-per-referral");

      if (payment_provider !== "stripe") {
        show_layout_modal(`Cannot create invoice for ${payment_provider} payment provider`);
        e.preventDefault();
      }

      if (pay_per_referral) {
        show_layout_modal(`Cannot create invoice for pay per referral payment option`);
        e.preventDefault();
      }
    });
  }
}

$(document).on('turbo:load', function() {
  if ($("body.admin_hosters.invoices").length == 0 ) {
    return;
  }
  console.log("page load in admin_hoster_invoices.js");

  var admin_hoster_invoices = new AdminHosterInvoices();
  admin_hoster_invoices.loadInvoiceList();
  admin_hoster_invoices.setupRefreshButton();
  admin_hoster_invoices.setupCreateMonthlyInvoice();
} );
