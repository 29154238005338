class AdminJobStatusPage {
  relaod_unless_complete() {
    if ( [ 'new', 'running' ].includes($('#state').text()) ) {
      console.debug("Will reload page in 5s");
      setTimeout( () => {
        const urlParams = new URLSearchParams(window.location.search);
        var num = urlParams.get('num');
        if (num !== null) {
          num = parseInt(num, 10);
          num += 1;
        } else {
          num = 1;
        }
        var newUrl = this.updateUrlParam(window.location.href, 'num', num);
        Turbo.visit(newUrl);
      }, 3000);
    }
  }

  updateUrlParam(url, paramName, newParamValue) {
    const urlObj = new URL(url);
    urlObj.searchParams.set(paramName, newParamValue);
    return urlObj.toString();
  }

  setupOpenResult() {
    $('#open-result').on('click', (e) => {
      e.preventDefault();
      var html = $('#result').html();
      var full = `<html><body>${html}</body></html>`;
      console.log(full);
      var newTab = window.open("", "_blank");
      newTab.document.write(full);
      newTab.document.close();
    });
  }
}

$(document).on('turbo:load', function() {
  if ($("body.admin_job_statuses.show").length == 0) {
    return;
  }
  console.log("page load in admin_job_status.js");

  var page = new AdminJobStatusPage();
  page.relaod_unless_complete();
  page.setupOpenResult();
} );
