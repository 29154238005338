import { showEphemeral, show_layout_modal } from './global';

class AdminPostsPage {
  constructor() {
  }

  setupPreviewButton() {
    $('#preview-btn').on('click', (event) => {
      event.preventDefault();
      this.displayPreview();
    });
  }

  displayPreview() {
    var body = $('textarea#post_body').val();
    var post_id = $('#preview-btn').data('post-id');
    $.ajax({ method: "PUT",
      url: `/admin/posts/${post_id}/preview`,
      contentType: "application/json",
      data: JSON.stringify({ post: { body: body }}),
      dataType: 'html',
      success: function(data, textStatus, xhr) {
        console.log("put success");
        $('#preview').html(data);
        $('#preview').removeClass('d-none');
        $('textarea#post_body').addClass('d-none');
        $('#preview-btn').addClass('d-none');
        $('#edit-btn').removeClass('d-none');
      },
      error: function(data, textStatus, xhr) {
        console.error(`err: ${data}, code: ${data.status}`);
        }
    });
  }

  setupSaveBodyButton() {
    $('#save-body-btn').on('click', (event) => {
      event.preventDefault();
      this.saveBody();
    })
  }

  saveBody() {
    var body = $('textarea#post_body').val();
    var post_id = $('#preview-btn').data('post-id');
    if (typeof post_id === 'undefined' || post_id.length == 0) {
      showEphemeral("Error: missing post id");
      return;
    }

    $.ajax({ method: "PUT",
      url: `/admin/posts/${post_id}/save_body`,
      contentType: "application/json",
      data: JSON.stringify({ post: { body: body }}),
      dataType: 'json',
      success: function(data, textStatus, xhr) {
        console.log("save body success");
        showEphemeral("Post body successfully saved.");
      },
      error: function(data, textStatus, xhr) {
        var respBody = data.responseJSON;
        console.error(`err: ${respBody.error}, code: ${data.status}`);
      }
    });
  }

  hidePreview() {
    $('#preview').addClass('d-none');
    $('textarea#post_body').removeClass('d-none');
    $('#preview-btn').removeClass('d-none');
    $('#edit-btn').addClass('d-none');
  }

  setupEditButton() {
    $('#edit-btn').on('click', (event) => {
      event.preventDefault();
      this.hidePreview();
    });
  }

  setupShortcut() {
    $("body.admin_posts").on("keydown", (e) => {
      if (e.ctrlKey && (e.key === 'l' || e.key === 's')) {
        e.preventDefault();
      }
    });

    $("body.admin_posts").on("keyup", (e) => {
      if (e.ctrlKey && e.key === 'l') {
        if ($('#edit-btn').hasClass('d-none'))
          this.displayPreview();
        else
          this.hidePreview();
      } else if (e.ctrlKey && e.key === 's') {
        e.preventDefault();
        this.saveBody();
      }
    });
  }
}

$(document).on('turbo:load', function() {
  if ($("body.admin_posts.edit").length == 0 && $("body.admin_posts.new").length == 0) {
    return;
  }
  console.log("page load in admin_posts.js");

  var admin_posts_page = new AdminPostsPage();

  admin_posts_page.setupPreviewButton();
  admin_posts_page.setupEditButton();
  admin_posts_page.setupShortcut();
  admin_posts_page.setupSaveBodyButton();
} );
