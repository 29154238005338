
class AdminHosterTraitsPage {
  init() {
    this.setupEditButtons();
    this.setupCancelButtons();
    this.setupSaveButtons();
    this.setupDeleteButtons();
    this.setupAddButtons();
    this.setupHosterFilterChange();

    $('#hoster-filter').trigger('focus');
  }

  setupEditButtons() {
    $('.traits-list').on('click', 'a.edit-button', (event) => {
      event.preventDefault();
      var wrapper = $(event.currentTarget).parents('div.trait-wrapper').first();
      var traitText = $(wrapper).find('.trait-show-value').text();
      $(wrapper).find('.trait-edit-value').val(traitText);
      $(wrapper).children('.trait-show').addClass('d-none');
      $(wrapper).children('.trait-edit').removeClass('d-none');
      $(wrapper).find('.trait-edit-value').trigger('focus');
    });
  }

  setupCancelButtons() {
    $('.traits-list').on('click', 'a.cancel-button', (event) => {
      event.preventDefault();
      var wrapper = $(event.currentTarget).parents('div.trait-wrapper').first();
      $(wrapper).children('.trait-show').removeClass('d-none');
      $(wrapper).children('.trait-edit').addClass('d-none');
    });
  }

  setupSaveButtons() {
    $('.traits-list').on('click', 'a.save-button', (event) => {
      event.preventDefault();

      var wrapper = $(event.currentTarget).parents('div.trait-wrapper').first();
      var hosterId = $(wrapper).data('hoster-id');
      var type = $(wrapper).data('type');
      var oldTrait = $(wrapper).find('.trait-show-value').text();
      var newTrait = $(wrapper).find('.trait-edit-value').val();

      $.ajax({ method: "PUT",
        url: `/admin/hosters/${hosterId}/trait/${type}`,
        data: { old: oldTrait, new: newTrait },
        dataType: 'json',
        success: function() {
          console.log("put success");
          $(wrapper).find('.trait-show-value').text(newTrait);
          $(wrapper).children('.trait-edit').addClass('d-none');
          $(wrapper).children('.trait-show').removeClass('d-none');
          $('#flash').addClass("alert-info");
          $('#flash').removeClass("alert-danger");
          $('#flash').html(`Hoster ${type} successfully updated.`);
        },
        error: function() {
          console.log("put failure");
          $('#flash').addClass("alert-danger");
          $('#flash').removeClass("alert-info");
          $('#flash').html(`Failed to update Hoster ${type}.`);
        }
      });
    });
  }

  setupDeleteButtons() {
    $('.traits-list').on('click', 'a.delete-button', (event) => {
      event.preventDefault();

      var wrapper = $(event.currentTarget).parents('div.trait-wrapper').first();
      var hosterId = $(wrapper).data('hoster-id');
      var type = $(wrapper).data('type');
      var oldTrait = $(wrapper).find('.trait-show-value').text();

      $.ajax({ method: "PUT",
        url: `/admin/hosters/${hosterId}/trait/${type}`,
        data: { old: oldTrait, new: "" },
        dataType: 'json',
        success: function() {
          console.log("delete success");
          $('#flash').addClass("alert-info");
          $('#flash').removeClass("alert-danger");
          $('#flash').html(`Hoster ${type} successfully deleted.`);
          $(wrapper).children('.trait-show').remove();
        },
        error: function() {
          console.log("delete failure");
          $('#flash').addClass("alert-danger");
          $('#flash').removeClass("alert-info");
          $('#flash').html(`Failed to delete Hoster ${type}.`);
        }
      });
    });
  }

  setupAddButtons() {
    $('.traits-list-wrapper').on('click', 'a.add-button', (event) => {
      event.preventDefault();

      var wrapper = $(event.currentTarget).parents('.traits-list-wrapper');
      var hosterId = $(event.currentTarget).parents('.hoster-section').data('hoster-id');
      var type = $(wrapper).data('trait-type');

      $.get( `/admin/hosters/${hosterId}/trait/${type}/new`, (data) => {
        var item = $(data);
        $(wrapper).children('.traits-list').append(item);
        $(item).find('.trait-show').addClass('d-none');
        $(item).find('.trait-edit').removeClass('d-none');
        $(item).find('.trait-edit-value').trigger('focus');
      });
    });
  }

  setupHosterFilterChange() {
    $('#hoster-filter').on("input", () => {
      var pattern = $('#hoster-filter').val();
      $('.hoster-section').each( (index, element) => {
        if ( $(element).data('hoster-name').toLowerCase().includes(pattern.toLowerCase())) {
          $(element).removeClass('d-none');
        } else {
          $(element).addClass('d-none');
        }
      });
    });
  }

}

$(document).on('turbo:load', function() {
  if ($("body.admin_hosters.all_traits").length == 0 ) {
    return;
  }
  console.log("page load in admin_hoster_traits.js");

  var traits_page = new AdminHosterTraitsPage();
  traits_page.init();
} );
