import VChartsLoader from './lib/vcharts_loader.js';
import { intersectionObserverLazyLoad } from './global';

class CompareManagedVpsPage {
  constructor() {
    this.vchartsLoader = new VChartsLoader();
  }

  init() {
    this.vchartsLoader.init();
    this.setupLazyLoadCharts();
  }

  setupLazyLoadCharts() {
    intersectionObserverLazyLoad("lazy-chart", "300px", (container) => {
      this.load_simple_chart(`#${container.id}`);
    });
  }

  load_simple_chart(canvasId) {
    var canvas = $(canvasId);
    if (canvas.length == 0) {
      console.error(canvas_id + " is missing from yabs page");
      return;
    }

    var ctx = canvas.get(0).getContext("2d");
    var num_cores = canvas.data('num-cores');
    var metrics = canvas.data('metrics');
    $.ajax({
      type: 'GET',
      url: `/compare/managed_vps/chart_data/prices/${num_cores}/${metrics}`,
      data: {},
      dataType: 'json',
      success: (data) => {
        console.log("success managed_vps price charts_data");
        this.vchartsLoader.simple_chart(ctx, 'bar', data);
      }
    })
  }
}

$(document).on('turbo:load', function() {
  if ($("body.compare_managed_vps.index").length == 0) {
    return;
  }
  console.log("page load in compare_managed_vps.js");

  var compare_mvps_page = new CompareManagedVpsPage();
  compare_mvps_page.init();
});
