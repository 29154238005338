import { EditTablePage } from "./lib/edit_table_page.js";

class AdminStoragePricesPage {

  setup_edit_table_page() {
    this.edit_table_page = new EditTablePage('table', '/admin/storage_prices');
  }

  load_storage_prices(pattern, page) {
    this.edit_table_page.load_table(pattern, page);
  }

  setup_reset_filter_callback() {
    this.edit_table_page.setup_reset_filter_callback();
  }

  setup_pagination() {
    this.edit_table_page.setup_pagination();
  }
}

$(document).on('turbo:load', function() {
  if ($("body.admin_storage_prices").length == 0) {
    return;
  }
  console.log("page load in admin_storage_prices.js.");

  var page = new AdminStoragePricesPage();
  page.setup_edit_table_page();
  page.load_storage_prices();
  page.setup_pagination();
  page.setup_reset_filter_callback();
} );

