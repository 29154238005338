import { show_layout_modal } from "./global";

class AdminToProvidersSubscriptions {

  setup() {
    this.setup_enabled_checkbox();
  }

  setup_enabled_checkbox() {
    $('#subs-table').on('change', 'input[type="checkbox"].sub-enabled-box', (e) => {
      var enabled = $(e.currentTarget).is(':checked');
      var row = $(e.currentTarget).parents('tr').first();
      var sub_id = row.data('subscription-id');
      var emailable_id = row.data('emailable-id');
      var emailable_type = row.data('emailable-type');
      this.update_subscription(row, sub_id, emailable_id, emailable_type, enabled);
    })
  }

  update_subscription(row, sub_id, emailable_id, emailable_type, enabled) {
    var meta = $('meta[name=csrf-token]');
    var csrfToken = meta.attr('content');
    $.ajax({ method: "PUT",
             url: `/admin/to_providers_subscriptions/${sub_id}`,
             headers: { "X-CSRF-Token": csrfToken },
             data: { emailable_id: emailable_id,
                      emailable_type: emailable_type, enabled: enabled },
            success: (data, textStatus, xhr) => {
              console.log(`update subscription success - ${textStatus} - ${xhr.status}`);
              var newRow = $($.parseHTML(data));
              $(row).replaceWith(newRow);
              return true;
            },
            error: (resp, textStatus, xhr) => {
              console.log(`update failure - code=${resp.status}`);
              var respBody = resp.responseJSON;
              show_layout_modal(`Failed to update subscription: ${respBody.error}`);
              return false;
            }
    });
  }
}

$(document).on('turbo:load', function() {
  if ($("body.admin_to_providers_subscriptions").length == 0) {
    return;
  }
  console.log("page load in admin_to_prov_subs.js.");

  var page = new AdminToProvidersSubscriptions();
  page.setup();
} );

