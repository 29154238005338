import { EditTablePage } from "./lib/edit_table_page.js";
import { SelectizeMultipleSelect } from "./lib/selectize_multiple_select";

class AdminProductRangesPage {
  setup_edit_table_page() {
    this.edit_table_page = new EditTablePage('table', '/admin/product_ranges', '',
                                    () => this.setup_all_hoster_plan_selects(),
                                    (row) => this.setup_hoster_plan_select($(row).find(".custom-plans-select").first())
                                  );
  }

  setup_all_hoster_plan_selects() {
    $(".custom-plans-select").each( (index, elt) => {
      this.setup_hoster_plan_select(elt);
    });
  }

  setup_hoster_plan_select(select_elt) {
    var selectizeBox = new SelectizeMultipleSelect(`#${$(select_elt).attr('id')}`, 'Type provider or plan names', 'plans', undefined, undefined, false);
    selectizeBox.init_select();
    selectizeBox.setup_select_changes();
  }

  load_product_ranges(pattern, page) {
    console.debug('load_product_ranges');
    this.edit_table_page.load_table(pattern, page);
  }

  setup_reset_filter_callback(){
    this.edit_table_page.setup_reset_filter_callback();
  }

  setup_pagination() {
    this.edit_table_page.setup_pagination();
  }
}

$(document).on('turbo:load', function() {
  if ($("body.admin_product_ranges.index").length == 0) {
    return;
  }
  console.log("page load in admin_product_ranges.js");

  var page = new AdminProductRangesPage();
  page.setup_edit_table_page();
  page.load_product_ranges();
  page.setup_pagination();
  page.setup_reset_filter_callback();
} );
