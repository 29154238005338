import { show_layout_modal } from "./global";

class AdminSpecialMentionsPage {
  constructor() {
  }

  init() {
    this.init_update_buttons('.publish-button', 'published');
    this.init_update_buttons('.deprecate-button', 'deprecated');
    this.init_destroy_buttons();
    this.init_create_buttons();
  }

  csrf_token() {
    var meta = $('meta[name=csrf-token]');
    return meta.attr('content');
  }

  init_update_buttons(button_class, new_state) {
    $('.special-mentions-table').on('click', button_class, (e) => {
      e.preventDefault();
      var sm_id = $(e.currentTarget).parents('tr.sm-row').data('sm-id');
      console.debug("sm id =", sm_id);
      $.ajax({
        method: "PUT",
        url: `/admin/special_mentions/${sm_id}`,
        headers: { "X-CSRF-Token": this.csrf_token() },
        contentType: "application/json",
        dataType: 'json',
        data: JSON.stringify({ state: new_state }),
        success: (data) => {
          var show_row = $(`tr#show-row-${sm_id}`);
          show_row.remove();
          if (new_state === 'published') {
            $('table.published').append($.parseHTML(data.html));
          }
        },
        error: (data) => this.handle_error(data)
      });
    });
  }

  init_create_buttons() {
    $('.create-sm-form').on('submit', (e) => {
      e.preventDefault();
      console.log($(e.currentTarget).find('.btn'));
      var sm_name = $(e.currentTarget).find('.btn').data('name');
      console.log("sm_name", sm_name);
      $.ajax({
        method: "POST",
        url: `/admin/special_mentions/`,
        headers: { "X-CSRF-Token": this.csrf_token() },
        contentType: "application/json",
        dataType: 'json',
        data: JSON.stringify({ name: sm_name }),
        success: (data) => {
          $('table.unpublished').append($.parseHTML(data.html));
        },
        error: (data) => this.handle_error(data)
      });
    });
  }

  init_destroy_buttons() {
    $('.special-mentions-table').on('click', '.destroy-button', (e) => {
      e.preventDefault();
      var sm_id = $(e.currentTarget).parents('tr.sm-row').data('sm-id');
      console.debug("sm id =", sm_id);
      $.ajax({
        method: "DELETE",
        url: `/admin/special_mentions/${sm_id}`,
        headers: { "X-CSRF-Token": this.csrf_token() },
        dataType: 'json',
        success: (data) => {
          $(`tr#show-row-${sm_id}`).remove();
        },
        error: (data) => this.handle_error(data)
      });
    });
  }

  handle_error(data) {
    var respBody = data.responseJSON;
    var err_msg = (typeof respBody !== 'undefined') ? respBody.error : `${data.status} - Server returned no error message`
    console.warn(`err: ${err_msg}, code: ${data.status}`);
    show_layout_modal(err_msg);
  }
}

$(document).on('turbo:load', function() {
  if ($("body.admin_special_mentions.index").length == 0 ) {
    return;
  }
  console.log("page load in admin_special_mentions.js");

  var admin_special_mentions_page = new AdminSpecialMentionsPage();
  admin_special_mentions_page.init();
} );
